#logosScroller .card {
    margin: 0 2.2em;
    padding: 2rem;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
}

#logosScroller .arrow {
    background: var(--mnr-red);
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    font-size: 2rem;
    top: 40%;
    transform: translateY(-50%);
    transition: all ease 0.3s;
}

#logosScroller .arrow:hover {
    opacity: .7;
    color: #fff;
}

#logosScroller .next {
    right: 0;
}

#logosScroller .prev {
    left: 0;
}