/* Navbar styles */

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.logo { 
    height: 50px;
}

.sign-in, .sign-up {
    font-size: 12px !important;
    color: white !important;
    background-color: var(--mnr-gold);
    margin: 0 5px;
    padding: 8px 20px !important;
}

.sign-up {
    background-color: var(--mnr-green);
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    border-bottom: none !important;
}

/*login modal css*/
.login-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    --bs-modal-width: 100% !important;
}

.login-modal-body {
    width: 100%;
    display: block;
    padding: 0px !important;
}

.login-modal-header {
    width: 100% !important;
}

.login-modal-card-main-section {
    /* box-shadow: 0px 0px 10px 1px #cdcdcd; */
    border-radius: 15px;
    background-color: #c5c5c512;
}

.login-card-section {
    width: 100%;
}

.card-login {
    padding: 50px;
}

.card-login-50 {
    /*padding: 50px !important;*/
    padding: 25px 30px !important;
    width: 520px;
}

.card-login h1 {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    /* color: #cf3038; */
    color: #2e2c2c;
    margin-bottom: 20px;
}

.forgot-password-section {
    text-align: right;
    margin-bottom: 20px;
}

    .forgot-password-section a {
        color: #000 !important;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
    }

        .forgot-password-section a:hover {
            color: #35744a;
        }

.forget_pass a {
    color: #2e2c2c;
    cursor: pointer;
    text-decoration: underline !important;
}

.submit-button-section {
    width: 100%;
    display: flex;
    justify-content: right;
}

.sign-up-link-section h4 {
    font-size: 16px;
    color: #9b9797;
    font-weight: 600;
    text-align: center;
}

    .sign-up-link-section h4 span a {
        color: #72C02C !important;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
    }
.hr-login-heading {
    margin-bottom: 23px !important;
    margin-top: 0px !important;
    opacity: 0.10;
}
.forgot-login-link{
    cursor:pointer;
}
.forgot-password-heading-inner h3 {
    font-size: 14px;
    color: #1d1d1d;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 22px;
}
.forgot-input-change {
    width: 100%;
    margin-bottom: 40px;
}
.reset-password-img-section img {
    width: 150px;
}
.reset-password-img-section {
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 25px;
    margin-top: 30px;

}
.resend-heading h3{
    font-size: 12px;
    text-align: center;
}
.main-section-captcha {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}