footer {
    font-size: 13px;
    color: #ffffff;
    width: 100%;
}

footer .copyright {
    background: #222;
    border-top: none;
    padding: 5px 0 5px;
}

footer p {
    margin: 15px;
}

footer a {
    color: var(--mnr-green)!important;
}
