* {
    padding: 0;
    margin: 0;
}

.submit-button {
    width: 100%;
    padding: 15px !important;
    background-color: #cf3038 !important;
    border: transparent !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-bottom: 20px;
}

    .submit-button:hover {
        background-color: #972026 !important;
    }

.btn-sign-in {
    width: 100%;
    background-color: transparent;
    color: #72C02C;
    border: 2px solid #72C02C;
    border-radius: 30px;
    margin-bottom: 12px;
    padding: 12px !important;
    text-transform: capitalize;
    font-weight: 600;
}
.btn-sign-in:hover {
    border-color: unset !important;
}
