/* Headings font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* Body font */
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-9');

/* Variables */
:root {
    --mnr-gold: #C7AB61;
    --mnr-red: #DB333B;
    --mnr-green: #72C02C;
    --mnr-yellow: #FFBC2D;
    --mnr-light-grey: #FAF9F9;
    --mnr-dark-grey: #B1B0B0;
}

/* General styles */

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
}

h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 3rem;
    line-height: 3.5rem;
}

h2 {
    font-size: 2.5rem;
    line-height: 3rem;
}

h3 {
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: var(--mnr-red);
}

h4 {
    font-size: 1rem;
    line-height: 2rem;
    color: var(--mnr-red);
}


p {
    line-height: 1.5rem;
    margin-bottom: 3rem;
    font-weight: 300;
}

a {
    color: var(--mnr-gold) !important;
    opacity: 1;
    transition: all ease 0.3s;
}

    a:hover {
        opacity: .7;
    }

.container-fluid {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

.btn {
    border-radius: 0;
    border: 0;
    color: white;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    padding: 1rem;
    opacity: 1;
    transition: all ease 0.3s;
}

    .btn:hover {
        color: white;
        opacity: .7;
    }

.btn-primary, .btn-primary:hover {
    background-color: var(--mnr-green);
}

.btn-secondary, .btn-secondary:hover {
    background-color: var(--mnr-gold);
}

.btn-tertiary, .btn-tertiary:hover {
    background-color: var(--mnr-yellow);
}

.alternative-section {
    background-color: var(--mnr-light-grey);
}

/************************************** HOME PAGE STYLES **************************************/

/* Hero section */

#hero span {
    color: var(--mnr-red);
}

#hero .lead {
    line-height: 2rem;
}

/* Industries & Companies section */

.industries {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    list-style: none;
    padding: 0;
}

    .industries li {
        background-color: var(--mnr-light-grey);
        padding: 1rem;
        border-radius: 1rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

        .industries li:before {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            background-color: white;
            display: inline-block;
            margin-right: 1rem;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
        }

.oil-gas li:nth-child(1):before {
    content: url("../img/exploration.svg");
}

.oil-gas li:nth-child(2):before {
    content: url("../img/equipment.svg");
}

.oil-gas li:nth-child(3):before {
    content: url("../img/pipeline.svg");
}

.power li:nth-child(1):before {
    content: url("../img/renewable.svg");
}

.power li:nth-child(2):before {
    content: url("../img/conventional.svg");
}

.power li:nth-child(3):before {
    content: url("../img/transmission.svg");
}

/* Guidance / rules section */

#guidance h3:before {
    content: url("../img/checked.svg");
    display: inline-block;
    margin-right: .5rem;
    vertical-align: middle;
    transform: scale(.9);
    line-height: 1rem;
}

/* Hiring */
#hiring {
    text-align: center;
}

    #hiring h4 {
        font-size: .8rem;
        text-transform: uppercase;
    }

    #hiring p {
        font-size: .8rem;
    }

    #hiring h4, #hiring img {
        margin-bottom: 1.5rem;
    }

.arrow-right-down {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.arrow-left-up {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1,-1);
}

.arrow-left-down {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

/* Only for Mobile */
.extra-arrow {
    margin-bottom: -3rem;
}

/* Important Information */


#importantInfo ul li, #jobDetails ul li {
    border: none;
    text-indent: -1.3em;
    padding-left: 3em;
    margin-bottom: 1.5rem;
}

    #importantInfo ul li:before, #jobDetails ul li:before {
        content: url("../img/checked.svg");
        display: inline-block;
        margin-right: .5rem;
        vertical-align: middle;
        transform: scale(.8);
        line-height: 1rem;
    }

/* Testimonials */

#testimonials {
    text-align: center;
}

    #testimonials p {
        font-size: .8rem;
        font-weight: 700;
    }

/************************************** JOBS PAGE STYLES **************************************/

/* Intro */

#jobsIntro, #similarJobsIntro, #applicationIntro {
    text-align: center;
    padding: 5rem 0;
}


    #jobsIntro h1, #applicationIntro h1 {
        font-weight: 300;
        margin-bottom: 1.5rem;
    }

    #jobsIntro p, #applicationIntro p {
        color: var(--mnr-dark-grey);
    }

/* Search widget */

#jobSearchWidget {
    text-align: center;
    padding: 5rem 0;
}

    #jobSearchWidget .job-search {
        padding: 2rem;
        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    /*#job-search-widget .field-wrapper {
    position: relative;
}*/

    #jobSearchWidget .field-wrapper .mb-3 {
        margin: 0 !important;
    }

    #jobSearchWidget label, #jobSearchWidget .job-search input, #jobSearchWidget .job-search input::placeholder, #jobSearchWidget .job-search select {
        border: none;
        font-size: 1.1rem;
        color: var(--mnr-dark-grey);
    }

    #jobSearchWidget .job-search input {
        border-radius: 0;
        margin-left: 1rem;
    }

    #jobSearchWidget .job-search .search-field-icon:before, #jobSearchWidget .job-search .cities-field-icon:before, #jobSearchWidget .job-search .job-family-field-icon:before {
        content: url("../img/magnify.svg");
        transform: scale(.8) translateY(-50%);
        display: block;
        position: absolute;
        top: 50%;
    }

    #jobSearchWidget .job-search .cities-field-icon:before {
        content: url("../img/map-location.svg");
    }

    #jobSearchWidget .job-search .job-family-field-icon:before {
        content: url("../img/briefcase.svg");
    }

    #jobSearchWidget .field-wrapper:after {
        content: "";
        display: inline-block;
        height: 70px;
        width: 1px;
        background-color: var(--mnr-light-grey);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    #jobSearchWidget .field-wrapper:last-child:after {
        content: none;
    }

.form-select {
    background-image: url("../img/grey-caret.svg");
    background-size: 7px;
    padding-left: 30px;
}

#jobFamilyField {
    padding-left: 40px;
}

#jobSearchWidget .job-search textarea:focus,
#jobSearchWidget .job-search textarea.form-control:focus,
#jobSearchWidget .job-search input.form-control:focus,
#jobSearchWidget .job-search input[type=text]:focus,
#jobSearchWidget .job-search input[type=password]:focus,
#jobSearchWidget .job-search input[type=email]:focus,
#jobSearchWidget .job-search input[type=number]:focus,
#jobSearchWidget .job-search [type=text].form-control:focus,
#jobSearchWidget .job-search [type=password].form-control:focus,
#jobSearchWidget .job-search [type=email].form-control:focus,
#jobSearchWidget .job-search [type=tel].form-control:focus,
#jobSearchWidget .job-search [contenteditable].form-control:focus
#jobSearchWidget .job-search select:focus,
#jobSearchWidget .job-search #jobCitiesField,
#jobSearchWidget .job-search #jobFamilyField {
    box-shadow: none !important;
}

#jobSearchWidget .advanced-search {
    font-size: 1.1rem;
    background-color: none;
    color: #000000;
    margin-top: 15px;
    float: left;
}

    #jobSearchWidget .advanced-search:after {
        content: url("../img/black-caret.svg");
        margin-top: -10px;
        margin-left: 15px;
    }

#jobSearchWidget .find-jobs-btn {
    min-width: 120px;
}

    #jobSearchWidget .find-jobs-btn, #jobSearchWidget .find-jobs-btn:hover {
        background-color: var(--mnr-green);
    }

#jobSearchWidget .dropdown {
    width: 100%;
    padding-left: 10px;
    position: unset;
}

#jobSearchWidget .mega-menu {
    width: 100% !important;
    position: absolute !important;
    inset: 0px 0px auto auto !important;
    transform: translate3d(0, 130px, 0) !important;
    padding: 2rem;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

    #jobSearchWidget .mega-menu label {
        margin-bottom: 1.5rem;
    }

    #jobSearchWidget .mega-menu input {
        background: var(--mnr-light-grey);
        margin-left: 0;
        height: 58px;
        margin-bottom: 2rem;
    }

    #jobSearchWidget .mega-menu .form-select, #jobsFilter .form-select {
        height: 58px;
        background-color: var(--mnr-light-grey);
        background-image: url("../img/grey-caret.svg");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 7px;
        border: none;
        color: var(--mnr-dark-grey);
        margin-bottom: 2rem;
        border-radius: 0;
    }

#jobSearchWidget input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("../img/date.svg") no-repeat;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: -5px;
}

/*#job-search-widget .mega-menu:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    right: 20%;
}
*/


/* Jobs filter */

#jobsFilter {
    padding: 5rem 0 2rem 0;
}


/* Jobs */
#jobsList .card, #jobDetailsIntro .card, #similarJobsList .card {
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    border-radius: 15px;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

#jobsList .odd, #jobDetailsIntro .odd, #similarJobsList .odd {
    background: #FFFFFF;
}

#jobsList .even, #jobDetailsIntro .even, #similarJobsList .even {
    background: var(--mnr-light-grey);
}

#jobsList .card h3, #jobDetailsIntro .card h3, #similarJobsList .card h3 {
    color: #000000;
    font-size: 18px;
}

#jobsList .card-img, #jobDetailsIntro .card-img, #similarJobsList .card-img {
    width: 10%;
    float: left;
    margin-right: 30px;
}

#jobsList .badge, #jobDetailsIntro .badge, #similarJobsList .badge {
    border-radius: 50px;
    font-size: 10px;
    font-weight: 400;
    padding: 5px 10px;
    margin: 0 5px;
}

#jobsList .green, #jobDetailsIntro .green, #similarJobsList .green {
    background: var(--mnr-green);
}

#jobsList .gold, #jobDetailsIntro .gold, #similarJobsList .gold {
    background: var(--mnr-gold);
}

#jobsList .featured, #jobDetailsIntro .featured, #similarJobsList .featured {
    color: var(--mnr-red);
    font-size: 12px;
    font-weight: 400;
    margin: 0 5px;
}

#jobsList .card-text, #jobDetailsIntro .card-text, #similarJobsList .card-text {
    font-size: 10px;
    color: var(--mnr-dark-grey);
}

    #jobsList .card-text span, #jobDetailsIntro .card-text span, #similarJobsList .card-text span {
        margin-right: 50px;
    }

    #jobsList .card-text .job-family, #jobDetailsIntro .job-family, #similarJobsList .card-text .job-family {
        margin-left: 20px;
    }

    #jobsList .card-text .job-salary, #jobDetailsIntro .job-salary, #similarJobsList .card-text .job-salary {
        margin-right: 0;
    }

    #jobsList .card-text span:before, #jobDetailsIntro .card-text span:before, #similarJobsList .card-text span:before {
        transform: scale(.7);
        position: absolute;
        margin-top: -5px;
        margin-left: -25px;
    }

    #jobsList .card-text .job-family:before, #jobDetailsIntro .job-family:before, #similarJobsList .job-family:before {
        content: url("../img/briefcase-1.svg");
    }

    #jobsList .card-text .job-location:before, #jobDetailsIntro .job-location:before, #similarJobsList .job-location:before {
        content: url("../img/location.svg");
    }

    #jobsList .card-text .job-date-added:before, #jobDetailsIntro .job-date-added:before, #similarJobsList .job-date-added:before {
        content: url("../img/clock.svg");
    }

    #jobsList .card-text .job-salary:before, #jobDetailsIntro .job-salary:before, #similarJobsList .job-salary:before {
        content: url("../img/money.svg");
    }

    #jobsList .card-text .job-links, #jobDetailsIntro .card-text .job-links, #similarJobsList .card-text .job-links {
        float: right;
        margin-top: 0 !important;
    }

        #jobsList .card-text .job-links span, #jobDetailsIntro .card-text .job-links span, #similarJobsList .card-text .job-links span {
            margin: 0;
        }

            #jobsList .card-text .job-links span a, #jobDetailsIntro .card-text .job-links span a, #similarJobsList .card-text .job-links span a {
                color: var(--mnr-green) !important;
                text-decoration: none;
                font-size: 14px;
            }

                #jobsList .card-text .job-links span a:after, #jobDetailsIntro .card-text .job-links span a, #similarJobsList .card-text .job-links span a:after {
                    content: url("../img/link-arrow.svg");
                    margin-left: 15px;
                }

.pagination {
    display: flex;
    justify-content: center;
}

    .pagination .page-item a {
        border: 0;
    }

        .pagination .page-item a:hover {
            background: none;
        }

/************************************** JOB DETAILS PAGE STYLES **************************************/

#jobDetailsIntro {
    margin-top: -15px;
}

    #jobDetailsIntro .card {
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 3rem 0;
        margin-bottom: 0 !important;
    }

    #jobDetailsIntro .card-body {
        padding: 0;
    }

        #jobDetailsIntro .card-body .badge-wrapper {
            margin-top: 1rem;
        }

            #jobDetailsIntro .card-body .badge-wrapper .badge {
                margin-left: 0;
                margin-right: 1rem;
            }

    #jobDetailsIntro .card-title {
        font-size: 26px !important;
    }

        #jobDetailsIntro .card-title:after {
            content: url("../img/check-2.svg");
            margin-left: 10px;
            line-height: 2;
            position: absolute;
        }

    #jobDetailsIntro .apply {
        font-weight: 600;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: #FFF !important;
        padding: 1rem 2rem;
        font-size: .8rem;
        text-transform: uppercase;
        letter-spacing: .1rem;
    }

#jobDetails h3 {
    color: #000;
    margin-bottom: 1.5rem;
    font-size: 22px;
}

#jobDetails ul li {
    list-style: none;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

    #jobDetails ul li:before {
        content: url("../img/checked.svg");
        display: inline-block;
        margin-right: .5rem;
        vertical-align: middle;
        transform: scale(.6);
        line-height: 1rem;
    }

#jobDetails .side-bar {
    background: var(--mnr-light-grey);
    padding: 2rem;
    position: relative;
}

    #jobDetails .side-bar h4 {
        color: #000;
    }

    #jobDetails .side-bar p {
        font-size: 13px;
    }

    #jobDetails .side-bar .job-detail {
        padding-left: 2rem;
    }

        #jobDetails .side-bar .job-detail:before {
            content: url("../img/job-details-icons/job-deadline.svg");
            display: inline-block;
            line-height: 2.5rem;
            position: absolute;
            left: 2rem;
        }

    #jobDetails .side-bar .job-family:before {
        content: url("../img/job-details-icons/job-family.svg");
    }

    #jobDetails .side-bar .job-title:before {
        content: url("../img/job-details-icons/job-title.svg");
    }

    #jobDetails .side-bar .job-level:before {
        content: url("../img/job-details-icons/job-level.svg");
    }

    #jobDetails .side-bar .job-experience:before {
        content: url("../img/job-details-icons/job-family-experience.svg");
    }

    #jobDetails .side-bar .industry-experience:before {
        content: url("../img/job-details-icons/oil-industry-experience.svg");
    }

    #jobDetails .side-bar .job-type:before {
        content: url("../img/job-details-icons/employment-type.svg");
    }

    #jobDetails .side-bar .location:before {
        content: url("../img/job-details-icons/location.svg");
    }

    #jobDetails .side-bar .city:before {
        content: url("../img/job-details-icons/city.svg");
    }

    #jobDetails .side-bar .compensation:before {
        content: url("../img/job-details-icons/money.svg");
    }

    #jobDetails .side-bar .reporting:before {
        content: url("../img/job-details-icons/reporting.svg");
    }

    #jobDetails .side-bar .reporting p {
        margin-bottom: 0;
    }

.side-bar .btn {
    color: #FFF !important;
    width: 100%;
}

#jobDetails .employer img {
    float: left;
    margin-bottom: 1rem;
}

#jobDetails .employer h4 {
    font-size: 1rem;
    line-height: 2rem;
    color: #000;
    display: inline-block;
    padding-top: 30px;
    margin-left: 30px;
}

    #jobDetails .employer h4 span {
        color: var(--mnr-red);
    }

#jobDetails .employer ul {
    list-style: none;
    padding-left: 0 !important;
    margin-bottom: 3rem;
    clear: left;
}

    #jobDetails .employer ul li {
        list-style: none;
        padding-left: 0 !important;
        text-indent: 0 !important;
        font-weight: 600;
    }

        #jobDetails .employer ul li:before {
            content: none;
        }

        #jobDetails .employer ul li span {
            font-weight: 300;
            float: right;
        }


/************************************** APPLICATION FORM PAGE STYLES **************************************/

.application-form {
    background: var(--mnr-light-grey);
    padding: 2.5rem;
    margin-bottom: 3rem;
    border-radius: 15px;
}

    .application-form label, .application-form p, .application-form #applicantCv {
        color: var(--mnr-dark-grey);
        font-size: 16px;
        font-weight: 400;
    }

    .application-form input, .application-form textarea {
        box-sizing: border-box;
        height: 65px;
        background: #FFFFFF;
        border: 1px solid rgba(177, 176, 176, 0.25);
        border-radius: 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
        margin-bottom: 3rem;
    }

    .application-form textarea {
        height: auto;
    }

    .application-form input::placeholder {
        color: var(--mnr-light-grey);
        font-size: 16px;
        filter: brightness(.9);
    }

    .application-form [type="file"] {
        height: auto;
    }

    .application-form [type="checkbox"] {
        height: 17px;
        margin-right: .5rem;
    }

    .application-form p {
        margin-bottom: 1rem;
    }

    .application-form .btn {
        color: #FFF !important;
    }

/************************************** JOB LISTING PAGE STYLES **************************************/

#jobSearchWidgetListing {
    text-align: center;
    padding: 5rem 0;
}

    #jobSearchWidgetListing .job-search {
        padding: 2rem;
        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    /*#job-search-widget .field-wrapper {
    position: relative;
}*/

    #jobSearchWidgetListing .field-wrapper .mb-3 {
        margin: 0 !important;
    }

    #jobSearchWidgetListing label, #jobSearchWidgetListing .job-search input, #jobSearchWidgetListing .job-search input::placeholder, #jobSearchWidgetListing .job-search select {
        border: none;
        font-size: 1.1rem;
        color: var(--mnr-dark-grey);
    }

    #jobSearchWidgetListing .job-search input {
        border-radius: 0;
        margin-left: 1rem;
    }

    #jobSearchWidgetListing .job-search .search-field-icon:before, #jobSearchWidgetListing .job-search .cities-field-icon:before, #jobSearchWidgetListing .job-search .job-family-field-icon:before {
        content: url("../img/magnify.svg");
        transform: scale(.8) translateY(-50%);
        display: block;
        position: absolute;
        top: 50%;
    }

    #jobSearchWidgetListing .job-search .cities-field-icon:before {
        content: url("../img/map-location.svg");
    }

    #jobSearchWidgetListing .job-search .job-family-field-icon:before {
        content: url("../img/briefcase.svg");
    }

    #jobSearchWidgetListing .field-wrapper:after {
        content: "";
        display: inline-block;
        height: 70px;
        width: 1px;
        background-color: var(--mnr-light-grey);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    #jobSearchWidgetListing .field-wrapper:last-child:after {
        content: none;
    }

.form-select {
    background-image: url("../img/grey-caret.svg");
    background-size: 7px;
    padding-left: 30px;
}

#jobFamilyFieldListing {
    padding-left: 40px;
}

#jobSearchWidgetListing .job-search textarea:focus,
#jobSearchWidgetListing .job-search textarea.form-control:focus,
#jobSearchWidgetListing .job-search input.form-control:focus,
#jobSearchWidgetListing .job-search input[type=text]:focus,
#jobSearchWidgetListing .job-search input[type=password]:focus,
#jobSearchWidgetListing .job-search input[type=email]:focus,
#jobSearchWidgetListing .job-search input[type=number]:focus,
#jobSearchWidgetListing .job-search [type=text].form-control:focus,
#jobSearchWidgetListing .job-search [type=password].form-control:focus,
#jobSearchWidgetListing .job-search [type=email].form-control:focus,
#jobSearchWidgetListing .job-search [type=tel].form-control:focus,
#jobSearchWidgetListing .job-search [contenteditable].form-control:focus
#jobSearchWidgetListing .job-search select:focus,
#jobSearchWidgetListing .job-search #jobCitiesField,
#jobSearchWidgetListing .job-search #jobFamilyField {
    box-shadow: none !important;
}

#jobSearchWidgetListing .advanced-search {
    font-size: 1.1rem;
    background-color: none;
    color: #000000;
    margin-top: 15px;
    float: left;
}

    #jobSearchWidgetListing .advanced-search:after {
        content: url("../img/black-caret.svg");
        margin-top: -10px;
        margin-left: 15px;
    }

#jobSearchWidgetListing .find-jobs-btn {
    min-width: 120px;
}

    #jobSearchWidgetListing .find-jobs-btn, #jobSearchWidgetListing .find-jobs-btn:hover {
        background-color: var(--mnr-red);
        border-radius: 30px;
    }

#jobSearchWidgetListing .dropdown {
    width: 100%;
    padding-left: 10px;
    position: unset;
}


#jobsFilterListing {
    padding: 5rem 0 2rem 0;
}

#jobSortListing, #paginationJobListig {
    font-size: 15px;
    padding: 10px 25px 10px 15px;
    background-color: var(--mnr-light-grey);
    border: none;
    color: var(--mnr-dark-grey);
    border-radius: 30px;
}

#jobsFilterListing .results-jobListing {
    font-weight: 500;
}

.left_job_container {
    background-color: var(--mnr-light-grey);
    border-radius: 15px 15px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.07)
}

#datePostedfilter {
    padding: 25px 25px 0px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07)
}

    #datePostedfilter legend {
        font-size: 17px;
        font-weight: 700;
    }

    #datePostedfilter .date-inputs {
        display: flex;
        justify-content: left;
        align-items: center;
    }

        #datePostedfilter .date-inputs Label {
            font-size: 12px;
            font-weight: 700;
            padding: 6px;
        }

        #datePostedfilter .date-inputs .form-check-input {
            width: 1.25em;
            height: 1.25em;
            margin-top: 0;
        }

            #datePostedfilter .date-inputs .form-check-input:focus {
                border: none;
                box-shadow: unset;
            }

            #datePostedfilter .date-inputs .form-check-input:checked {
                background-color: #000000;
                border-color: #000000;
            }

#employmentTypefilter {
    padding: 25px 25px 15px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07)
}

    #employmentTypefilter .emp_type_filter {
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    #employmentTypefilter .inner-emp-filtr div {
        padding: 3px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
    }

    #employmentTypefilter .inner-emp-filtr .colored-circle {
        height: 24px;
        width: 24px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
    }

    #employmentTypefilter .inner-emp-filtr .cl-1 {
        background: #f13838;
    }

    #employmentTypefilter .inner-emp-filtr .cl-2 {
        background: #45d745;
    }

    #employmentTypefilter .inner-emp-filtr .cl-3 {
        background: #4848b3;
    }

    #employmentTypefilter .inner-emp-filtr .cl-4 {
        background: #f1c010;
    }

    #employmentTypefilter .inner-emp-filtr .cl-5 {
        background: #e7932a;
    }

    #employmentTypefilter .inner-emp-filtr .cl-6 {
        background: #4ddfa2;
    }

#jobFamilyfilter {
    padding: 25px 25px 15px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07)
}

    #jobFamilyfilter .job_fam_filter {
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    #jobFamilyfilter .job-fam-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
    }

        #jobFamilyfilter .job-fam-inner Label {
            font-size: 12px;
            font-weight: 700;
            padding: 8px;
        }

        #jobFamilyfilter .job-fam-inner .form-check {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #jobFamilyfilter .job-fam-inner .form-check-input {
            width: 1.25em;
            height: 1.25em;
            margin-top: 0;
            border-radius: unset;
        }

            #jobFamilyfilter .job-fam-inner .form-check-input:focus {
                border-color:unset;
                box-shadow: unset;
            }
            #jobFamilyfilter .job-fam-inner .form-check-input:checked {
                background-color: var(--mnr-red);
                border-color: var(--mnr-red);
            }
    #jobFamilyfilter .jobfamily-red {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: var(--mnr-red)
    }

        #jobFamilyfilter .jobfamily-red Label {
            font-size: 12px;
            font-weight: 700;
            padding: 8px;
            color:var(--mnr-red)
        }

    #jobFamilyfilter .jobfamily-red .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--mnr-red)
    }

    #jobFamilyfilter .jobfamily-red .form-check-input {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0;
        border-radius: unset;
    }

        #jobFamilyfilter .jobfamily-red .form-check-input:focus {
            border-color: unset;
            box-shadow: unset;
        }
    #jobFamilyfilter .jobfamily-red .form-check-input:checked {
        background-color: var(--mnr-red);
        border-color:var(--mnr-red);
    }



#expandfilters .accordion-button {
    padding: 5px 25px;
    background-color: #eee;
    font-size: 17px;
    font-weight: 600;
    color: #b3a5a5;
}

    #expandfilters .accordion-button:focus {
        box-shadow: unset;
    }

    #expandfilters .accordion-button:not(.collapsed) {
        box-shadow: unset;
    }

#expandfilters .accordion-body {
    padding: 0;
}

#expandfilters .accordion-button::after {
    /*background-image: url("../img/add.png");*/
}

#expandfilters .accordion-button:not(.collapsed)::after {
    /*background-image: url("../img/minus.png");*/
}

#innerexpandfilters .accordion-button {
    background-color: transparent;
}

    #innerexpandfilters .accordion-button:not(.collapsed) {
        box-shadow: unset;
        color: #000000;
        padding: 5px 25px;
    }

#innerexpandfilters .offered-salary-filter {
    padding: 0px 25px 25px;
}

#innerexpandfilters .accordion-button::after {
    /*background-image: url("../img/down.png");*/
}

#innerexpandfilters .accordion-button:not(.collapsed)::after {
    /*background-image: url("../img/down.png");*/
}

#innerexpandfilters .offered-salary-filter .salary-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
}

    #innerexpandfilters .offered-salary-filter .salary-inner Label {
        font-size: 12px;
        font-weight: 700;
        padding: 10px;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner .form-check-input {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0;
        border-radius: unset;
    }
        #innerexpandfilters .offered-salary-filter .salary-inner .form-check-input:focus {
            border-color: unset;
            box-shadow: unset;
        }

#innerexpandfilters .offered-salary-filter .salary-inner .form-check-input:checked {
    background-color: var(--mnr-red);
    border-color: var(--mnr-red);
}

#innerexpandfilters .offered-salary-filter .salary-inner-red {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color:var(--mnr-red)
}

    #innerexpandfilters .offered-salary-filter .salary-inner-red Label {
        font-size: 12px;
        font-weight: 700;
        padding: 10px;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check-input {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0;
        border-radius: unset;
    }

        #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check-input:focus {
            border-color: unset;
            box-shadow: unset;
        }

        #innerexpandfilters .offered-salary-filter .salary-inner-red .form-check-input:checked {
            background-color: var(--mnr-red);
            border-color: var(--mnr-red);
        }
#jobcardlisting {
    margin-bottom: 20px;
}
    #jobcardlisting:nth-child(2n) .card-body {
        background: var(--mnr-light-grey);
        border-radius: 10px;
    }

    #jobcardlisting .job-card-head {
        font-size: 22px;
        font-weight: 700;
    }

        #jobcardlisting .job-card-head span {
            font-size: 12px;
            padding: 2px 20px;
            margin: 0px 5px;
            border-radius: 15px;
            font-weight: 400
        }

        #jobcardlisting .job-card-head .featured {
            padding: 0px 0px 0px 12px;
            font-size: 14px;
            color: var(--mnr-red);
            font-weight: 600;
        }

        #jobcardlisting .job-card-head .fulltime {
            background-color: var(--mnr-green);
            color: #ffffff;
        }

        #jobcardlisting .job-card-head .urgent {
            background-color: var(--mnr-gold);
            color: #ffffff;
        }

    #jobcardlisting .job-card-data {
        font-size: 10px;
        color: var(--mnr-dark-grey);
    }

        #jobcardlisting .job-card-data span {
            line-height: 24px;
            margin-right: 30px;
        }

    #jobcardlisting .card-body {
        padding: 20px
    }

    #jobcardlisting .job-card-para {
        font-weight: 500;
        font-size: 17px;
        padding: 15px 10px;
    }

    #jobcardlisting .job-card-img {
        height: 70px;
        width: 100%;
    }

    #jobcardlisting .shortlist-job-card {
        font-size: 13px;
        color: var(--mnr-dark-grey);
    }

        #jobcardlisting .shortlist-job-card .heart-icon-jobcard {
            font-size: 20px;
            color: #e30303;
            margin: 8px 6px 10px 6px;
        }

    #jobcardlisting .job-card-data b {
        margin-left: 7px;
    }

    #jobcardlisting .job-card-data span img {
        height: 15px
    }

    /*reset password css*/
.pssword-inp-reset-screens {
    position: relative !important;
}
.reset-icon-password-visible {
    position: absolute;
    top: 23px;
    right: 10px;
    font-size: 22px;
    color: #9b9797;
    cursor: pointer;
}