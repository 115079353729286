#testimonialsScroller .owl-item {
    padding: 50px;
    opacity: .2;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#testimonialsScroller .owl-item.active.center {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

#testimonialsScroller .owl-item img {
    max-width: 200px;
    margin: 0 auto 15px;
}

#testimonialsScroller .owl-dots .owl-dot.active span,
#testimonialsScroller .owl-dots .owl-dot:hover span {
    background: var(--mnr-red);
    transform: translate3d(0px, -50%, 0px) scale(0.7);
}

#testimonialsScroller .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
}

#testimonialsScroller .owl-dots .owl-dot {
    display: inline-block;
}

#testimonialsScroller .owl-dots .owl-dot span {
    background:  var(--mnr-red);
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
}
