
* {
    padding: 0;
    margin: 0;
}

.inputs-custom {
    padding: 10px 15px !important;
    font-size: 16px !important;
    border: 1px solid #e0e2e4 !important;
    margin-bottom: 15px;
}

.used-icon-password-visible {
    position: relative;
}

.inputs-custom:focus {
    box-shadow: 0px 0px 5px 0px #d2d2d2 !important;
    border-color: transparent !important;
}

.inputs-custom:disabled {
    background-color: #fff !important;
}

.label-section-inputs {
    font-size: 16px;
    color: #1d1d1d;
    /* font-weight: 400; */
    font-weight: 700;
}

.icon-password-visible {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 22px;
    color: #9b9797;
    cursor: pointer;
}

.add-csr-pointer {
    cursor: pointer;
}

.star-mandatory-field {
    color: red;
    font-size: 20px;
    margin-left: 5px;
}