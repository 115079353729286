/* Styles for smaller sceen sizes */

/*@media (min-width: 768px) {
    body {
        font-size: 16px;
    }
}*/

.navbar-toggler {
    background-color: var(--mnr-gold);
}

.navbar-toggler span {
    color: white!important;
}

@media (max-width: 768px) {

    .logo {
        max-width: 250px;
    }

    /* Navbar buttons */
    .sign-in, .sign-up {
        margin: 10px 0 0 0!important;
        display: lock;
        text-align: center;
    }

    /* Hero */

    #hero .lead {
       line-height: 2rem;
    }

    #hero .btn {
       min-width: 100%!important;
    }

    /* Hiring */
    .hiring-process-wrapper:after {
        display: none;
    }

    /* Job search widget */

    .field-wrapper {
        flex: none!important;
        display: block!important;
    }

    #jobSearchWidget .job-search .search-field-icon:before, #jobSearchWidget .job-search .cities-field-icon:before, #jobSearchWidget .job-search .job-family-field-icon:before {
        transform: scale(.6) translateY(0);
        top: unset;
        margin-top: 5px;
    }

    #jobSearchWidget .find-jobs-btn {
        width: 100% !important;
        margin-top: 2rem;
    }

    #jobSearchWidget .mega-menu {
        transform: translate3d(0, 400px, 0) !important;
    }

    /* Job list */
    #jobsList .card h3 {
        clear: left;
    }

    #jobsList .card img {
        width: 50%;
    }

    #jobsList .card h3 .featured {
        display: inline-block;
    }

    #jobsList .card .card-text span {
        display: block;
        margin-bottom: 20px;
        margin-left: 0;
        padding-left: 25px;
    }

    #jobsList .card .card-text .job-links {
        float: left;
        margin-top: 20px;
    }

    #jobsList .card .card-text .job-links span {
        float: left;
        padding: 0;
        margin-right: 25px;
    }
}
